import React, { useCallback, useMemo, useRef } from "react";
import { MdLogout } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { selectMobileNavState, toggle } from "../../redux/slices/mobileSlice";
import {
  adminMenu,
  superAdminRoutesAccess,
  userMenu,
} from "../../utils/_helper";
import Text from "../shared/Text";
import MobileNavLink from "../shared/MobileNavLink";
import { AdminPositions, CuzooAdminRoles } from "../../enums";
import { IAuth } from "../../interfaces";
import CuzooLogo from "../shared/CuzooLogo";
import { selectWithdrawalRequests } from "../../redux/slices/withdrawalRequestSlice";

interface Props {
  user: IAuth | null;
  logout: () => void;
}

function MobileNav({ user, logout }: Props) {
  const isOpen = useAppSelector(selectMobileNavState);
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const withdrawalRequests = useAppSelector(selectWithdrawalRequests);

  const totalRequest = useMemo(
    () =>
      [...withdrawalRequests.companies, ...withdrawalRequests.riders].length,
    [withdrawalRequests.companies, withdrawalRequests.riders]
  );

  const handleNavRemoval = useCallback(() => {
    dispatch(toggle());
  }, [dispatch]);

  return (
    <div className="block md:hidden">
      <div
        ref={wrapperRef}
        onClick={handleNavRemoval}
        role="none"
        className={`bg-background/80 ${
          isOpen ? "absolute" : "none"
        } inset-0 z-40`}
      />
      <nav
        className={`absolute py-5 px-3 h-screen overflow-y-scroll bg-primary z-50 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-all`}
      >
        <div className="flex flex-col items-center justify-between h-full">
          <div>
            <CuzooLogo />
          </div>

          <div className="py-5 flex-1 mt-3">
            {adminMenu.map(({ Icon, text, url }) => {
              if (
                superAdminRoutesAccess.includes(url) &&
                user &&
                user?.role < CuzooAdminRoles.SUPER_ADMIN
              )
                return null;

              return (
                <MobileNavLink
                  Icon={Icon}
                  text={text}
                  key={text}
                  to={url}
                  onClick={handleNavRemoval}
                  badge={url === "/finance" ? totalRequest : null}
                />
              );
            })}
          </div>

          <div className="w-full">
            {user?.position !== AdminPositions.INVESTOR
              ? userMenu.map(({ Icon, text, url }) => (
                  <MobileNavLink
                    Icon={Icon}
                    text={text}
                    key={text}
                    to={url}
                    onClick={handleNavRemoval}
                  />
                ))
              : null}

            <div
              className="my-2 px-5 py-2 rounded-md hover:bg-gray-200"
              title="Logout"
              role="none"
              onClick={() => logout()}
            >
              <div className="flex items-center justify-between">
                <MdLogout size={20} />

                <Text
                  content="Logout"
                  className="text-xs ml-2 transition-all flex-1"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default MobileNav;
