import dayjs from "dayjs";
import { HiOutlineCog } from "react-icons/hi";
import CompaniesSettings from "../components/shared/CompaniesSettings";
import FinancialSettings from "../components/shared/FinancialSettings";
import GeneralSettings from "../components/shared/GeneralSettings";
import Header from "../components/shared/Header";
import RidersSettings from "../components/shared/RidersSettings";
import UsersSettings from "../components/shared/UsersSettings";
import { useAppSelector } from "../hooks/useRedux";
import { selectSettings } from "../redux/slices/settingsSlice";
import { selectAuth } from "../redux/slices/authSlice";
import { AdminPositions } from "../enums";

function Settings() {
  const { general } = useAppSelector(selectSettings);
  const { user } = useAppSelector(selectAuth);
  return user?.position !== AdminPositions.INVESTOR ? (
    <div className="h-full">
      <Header>
        <div>
          <div className="flex items-center">
            <HiOutlineCog size={20} />
            <h2 className="text-lg leading-none ml-2">Settings</h2>
          </div>
          <span className="text-xs opacity-60 font-bold">
            Platform Settings
          </span>
          <span className="text-xs text-success block">
            Last Updated -{" "}
            {dayjs(general?.updatedAt.toDate()).format("DD/MM/YYYY@HH:mm:ssa")}
          </span>
        </div>
      </Header>
      <div className="p-2 bg-background h-full md:max-w-lg md:mx-auto">
        <GeneralSettings />
        <FinancialSettings />
        <UsersSettings />
        <RidersSettings />
        <CompaniesSettings />
      </div>
    </div>
  ) : null;
}

export default Settings;
